import React from "react"

import styles from "./styles.module.css"

const Button = ({ type, children, onPress, style }) => (
  <button
    style={style}
    className={`${styles.button} ${styles[type]}`}
    onPress={onPress}
  >
    {children}
  </button>
)

export default Button
