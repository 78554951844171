import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const metaTitle = title || site.siteMetadata.title

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={metaTitle}
      //titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: [
            "software development",
            "mobile apps",
            "mobile app development",
            "android app",
            "ios app",
            "Mobile Design",
            "web apps",
            "web app development",
            "Web Design",
            "cloud services",
            "computer programming",
            "software testing",
            "software design",
            "development team",
            "agile development",
            "driven development",
            "creating software",
            "development environment",
            "data science",
            "software engineering",
            "software development methodology",
            "object oriented programming",
            "writing code",
            "software consulting",
            "software project management",
            "react",
            "react-native",
            "nodejs",
            "API",
            "kubernetes",
            "Google Cloud",
            "serverless",
            "GraphQL",
            "UX/UI"
          ].join(","),
        },
        {
          property: `og:image`,
          content: `https://leaplane.io${require("../images/full_logo.png")}`,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://leaplane.io`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
