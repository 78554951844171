import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

import Button from "../Button/Button"
import Pontuation from "../Pontuation/Pontuation"

import styles from "./styles.module.css"
import { Link } from "gatsby"

const Footer = () => (
  <footer className={styles.footer}>
    <div className={styles.container}>
      <div className={styles.left}>
        <h1>
          Start your product today<Pontuation>.</Pontuation>
        </h1>
        <p>
          We’re ready to help you find solutions in technology. Get in touch
          with us and let’s work together towards you goals.
        </p>
        <Link to="/contacts">
          <Button type="primary">Let's talk</Button>
        </Link>
      </div>
      <div className={styles.rigth}>
        <div className={styles.row}>
          <div className={styles.col}>
            <h3>
              Meet us<Pontuation>.</Pontuation>
            </h3>
            <p>Centro Empresarial da Lionesa, C27</p>
            <p>4470-294, Leça do Balio</p>
            <p>Matosinhos, Portugal</p>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.col}>
            <h3>
              Contact us<Pontuation>.</Pontuation>
            </h3>
            <a href="mailto:geral@leaplane.io" className={styles.contact}>
              <FontAwesomeIcon
                style={{ opacity: 0.8, fontSize: 13, marginRight: 10 }}
                icon={faEnvelope}
              />
              <p>geral@leaplane.io</p>
            </a>
            <a href="tel:+351919030468" className={styles.contact}>
              <FontAwesomeIcon
                style={{ opacity: 0.8, fontSize: 13, marginRight: 10 }}
                icon={faPhone}
              />
              <p>+351 919 030 468</p>
            </a>
          </div>
          <div className={styles.col}>
            <h3>
              Follow us<Pontuation>.</Pontuation>
            </h3>
            <div className={`${styles.row} ${styles.social}`}>
              <a
                href="https://www.facebook.com/leaplanetech"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../images/facebook-square.png")}
                  alt="Facebook"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/leaplane"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={require("../../images/linkedin.png")}
                  alt="Linkedin"
                />
              </a>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <img
                  src={require("../../images/instagram.png")}
                  alt="Instagram"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
