import React from "react"

import styles from "./styles.module.css"

import { Link } from "gatsby"
import MobileNavbar from "./MobileNavbar"

const isActive = (path, url) =>
  path && url === "/"
    ? path === url
      ? styles.active
      : ""
    : path.includes(url)
    ? styles.active
    : ""

const Navbar = ({ path }) => (
  <nav className={styles.nav}>
    <div className={`${styles.container} ${styles.desktop}`}>
      <div className={styles.logo}>
        <img alt="icon" src={require("../../images/logo.png")} />
      </div>
      <div className={styles.row}>
        <Link className={`${styles.link} ${isActive(path, "/")}`} to="/">
          About
        </Link>

        <Link
          className={`${styles.link} ${isActive(path, "/faqs")}`}
          to="/faqs"
        >
          FAQs
        </Link>

        <Link
          className={`${styles.link} ${isActive(path, "/portfolio") || isActive(path, "/projects")}`}
          to="/portfolio"
        >
          Portfolio
        </Link>

        <Link
          className={`${styles.link} ${isActive(path, "/contacts")}`}
          to="/contacts"
        >
          Contacts
        </Link>
      </div>
    </div>
    <MobileNavbar path={path} />
  </nav>
)

export default Navbar
